import { FeedType, SortBy } from 'graphql/types'

export const MARKETING_SITE_URL = 'https://www.nsfw.app'
export const PAYMENT_INSTRUCTIONS_URL =
  'https://nsfwapp.notion.site/How-to-use-MetaMask-to-pay-for-content-using-USDC-879004391a8d4dec8bc25c15c605efbd'

export enum AppRoutes {
  FEED = '/feed',
  DISCOVER = '/discover',
  LATEST_FEED = '/latest',
  FOLLOWING_FEED = '/following',
  LIKES_FEED = '/likes',
  LOGIN = '/login',
  SIGNUP = '/signup',
  SIGNUP_PENDING = '/signup/pending',
  SIGNUP_DENIED = '/signup/unsuccessful',
  SIGNUP_KYC_STATUS = '/signup/kyc-status',
  RESET = '/reset-password',
  POST = '/post',
  SCHEDULED_POSTS = '/scheduled-posts',
  PROFILE = '',
  SEARCH = '/search',
  MESSAGES = '/messages',
  NOTIFICATIONS = '/notifications',
  EARNINGS = '/earnings',
  ANALYTICS = '/analytics',
  ACTIVE_SUBS = '/subscriptions/active',
  EXPIRED_SUBS = '/subscriptions/expired',
  PURCHASES = '/purchases',
  SALES = '/sales',
  REWARDS = '/rewards'
}

export const FEED_MAP = {
  [`${FeedType.LATEST}:${SortBy.RANDOM}`]: AppRoutes.DISCOVER,
  [`${FeedType.LATEST}:${SortBy.CREATED_AT}`]: AppRoutes.LATEST_FEED,
  [FeedType.FOLLOWING]: AppRoutes.FOLLOWING_FEED,
  [FeedType.LIKED]: AppRoutes.LIKES_FEED
}
