import { KnownIcon } from 'common/Icon'
import { MARKETING_SITE_URL } from './routes'

export const FOOTER_LINKS = [
  {
    name: 'Disclaimers',
    href: `${MARKETING_SITE_URL}/disclaimers`,
  },
]

interface SOCIAL_LINKS {
  icon: KnownIcon
  href: string
}

export const SOCIAL_LINKS: SOCIAL_LINKS[] = [
  { icon: 'Discord', href: 'https://discord.gg/XR8Ek4v7EM' },
  { icon: 'Twitter', href: 'https://twitter.com/OfficialNSFWApp' },
  { icon: 'Telegram', href: 'https://t.me/+pPehGwtUl4M2ZWIx' },
  {
    icon: 'Notion',
    href: 'https://nsfwapp.notion.site/NSFW-DAO-bb16149cf78648c1a157b086ab64b628',
  },
  {
    icon: 'WWW',
    href: MARKETING_SITE_URL,
  },
]
