import { PROD_OPERATIONS_ACCOUNT, STAGE_OPERATIONS_ACCOUNT } from '@nsfw-app/crypto'
import { SupportedEnv, SupportedEnvironments } from './environments'

export enum Wallet {
  METAMASK = 'METAMASK',
  WALLET_CONNECT = 'WALLET_CONNECT'
}

export interface WalletInfo {
  wallet: Wallet
  name: string
  description: string
  mobile?: true
  mobileOnly?: true
}

const getOperationsAddressByEnv = (env: SupportedEnvironments) => {
  switch (env) {
    case SupportedEnv.BETA:
      return PROD_OPERATIONS_ACCOUNT
    case SupportedEnv.STAGE:
      return STAGE_OPERATIONS_ACCOUNT
    default:
      throw new Error('Unsupported Environment')
  }
}

export const WITHDRAWAL_ADDRESS = getOperationsAddressByEnv(
  process.env.NEXT_PUBLIC_NSFW_ENV as SupportedEnvironments
)
