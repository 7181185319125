import nprogress from 'nprogress'
import Router from 'next/router'
import React, { ReactChild, useEffect, useState } from 'react'
import type { AppProps } from 'next/app'

import dynamic from 'next/dynamic'

import { base, baseSepolia, optimism, optimismSepolia, polygon, polygonAmoy } from 'viem/chains'
import { globalStyles } from 'lib/theme/global'
import { urqlClient } from 'lib/urql/client'
import { Heading } from 'primitives/Heading'

/**
 * ------------------------------
 * Vendor imports
 * ------------------------------
 */
const SbStateSync = dynamic(() => import('containers/SbStateSync').then((mod) => mod.SbStateSync), {
  ssr: false
})

const WalletStateSync = dynamic(
  () => import('containers/WalletStateSync').then((mod) => mod.WalletStateSync),
  {
    ssr: false
  }
)

/**
 * ------------------------------
 * Dynamic imports
 * ------------------------------
 */
const DynamicToaster = dynamic(() => import('sonner').then((mod) => mod.Toaster))
const DynamicPrivyProvider = dynamic(() =>
  import('@privy-io/react-auth').then((mod) => mod.PrivyProvider)
)
const DynamicMDXProvider = dynamic(() => import('@mdx-js/react').then((mod) => mod.MDXProvider))
const DynamicSbProvider = dynamic(() =>
  import('providers/SbProvider').then((mod) => mod.SbProvider)
)

const DynamicImage = dynamic(() => import('primitives/Image').then((mod) => mod.Image))
const DynamicText = dynamic(() => import('primitives/Text').then((mod) => mod.Text))
const DynamicBox = dynamic(() => import('primitives/Box').then((mod) => mod.Box))
const DynamicHeading = dynamic(() => import('primitives/Heading').then((mod) => mod.Heading))
const DynamicBlockquote = dynamic(() =>
  import('primitives/Blockquote').then((mod) => mod.Blockquote)
)
const DynamicLink = dynamic(() => import('common/Link').then((mod) => mod.Link))
const DynamicListItem = dynamic(() => import('primitives/ListItem').then((mod) => mod.ListItem))
const DynamicList = dynamic(() => import('primitives/List').then((mod) => mod.List))

const DynamicUrqlProvider = dynamic(() => import('urql').then((mod) => mod.Provider))
const DynamicStoreProvider = dynamic(() =>
  import('lib/store/StoreProvider').then((mod) => mod.StoreProvider)
)

/**
 * MDX components
 */
const MDX_COMPONENT_MAP = {
  img: DynamicImage,
  p: DynamicText,
  div: DynamicBox,
  h1: DynamicHeading,
  h2: ({ children }: { children: ReactChild }) => <Heading as="h2">{children}</Heading>,
  h3: ({ children }: { children: ReactChild }) => <Heading as="h3">{children}</Heading>,
  h4: ({ children }: { children: ReactChild }) => <Heading as="h4">{children}</Heading>,
  blockquote: DynamicBlockquote,
  a: DynamicLink,
  li: DynamicListItem,
  ul: ({ children }: { children: ReactChild }) => <DynamicList>{children}</DynamicList>
}

import 'react-intl-tel-input/dist/main.css'
import '@sendbird/uikit-react/dist/index.css'
import 'nprogress/nprogress.css'
import 'lib/theme/css/reset.css'

import { RootStore } from 'models/RootStore'

const PRIVY_APP_ID = process.env.NEXT_PUBLIC_PRIVY_APP_ID!

const startLoading = () => {
  if (typeof window !== 'undefined') {
    nprogress.start()
  }
}
const stopLoading = () => {
  if (typeof window !== 'undefined') {
    nprogress.done()
  }
}

// https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
const setAppHeight = () => {
  const doc = window.document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}

type AppPageProps = { store: Partial<RootStore> }

function MyApp({ Component, pageProps }: AppProps<AppPageProps>) {
  globalStyles()

  // Seperate store data to hydrate provider directly instead of pass to page props
  const { store, ...props } = pageProps
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setAppHeight()
    window.addEventListener('resize', setAppHeight)

    nprogress.configure({ showSpinner: false })
    Router.events.on('routeChangeStart', startLoading)
    Router.events.on('routeChangeComplete', stopLoading)

    setIsMounted(true)

    return () => {
      window.removeEventListener('resize', setAppHeight)
      Router.events.off('routeChangeStart', startLoading)
      Router.events.off('routeChangeComplete', stopLoading)
    }
  }, [])

  if (!isMounted) {
    return null
  }

  return (
    <DynamicPrivyProvider
      appId={PRIVY_APP_ID}
      config={{
        appearance: {
          accentColor: '#6A6FF5',
          theme: '#1B1C20',
          showWalletLoginFirst: false,
          logo: 'https://nsfwapp.s3.eu-central-1.amazonaws.com/logo-privy.png'
        },
        walletConnectCloudProjectId:
          // https://cloud.walletconnect.com/app/6ea27b7d-32cf-490a-b5c9-c8ffe5caf13f/project/9ce383de-67dd-45ff-b3dc-eb5e9c5a4216
          process.env.NEXT_PUBLIC_NSFW_ENV === 'beta'
            ? '7cdd0398e5dadbc0b1d98440eb77eced' //
            : undefined,
        loginMethods: ['wallet', 'sms', 'farcaster'],
        // Uncomment the following to enable embedded wallets
        // embeddedWallets: {
        //   createOnLogin: 'users-without-wallets',
        //   requireUserPasswordOnCreate: true
        // },
        mfa: { noPromptOnMfaRequired: false },
        // Import your desired chain from `viem/chains` and pass it to `defaultChain`
        defaultChain: process.env.NEXT_PUBLIC_NSFW_ENV !== 'beta' ? baseSepolia : base,
        // Replace this with a list of your desired supported chains
        // https://docs.privy.io/guide/react/configuration/networks#configuration
        supportedChains: [optimism, optimismSepolia, base, baseSepolia, polygon, polygonAmoy],
        fiatOnRamp: {
          useSandbox: true // This defaults to false
        }
      }}
    >
      <DynamicStoreProvider value={store}>
        <DynamicUrqlProvider value={urqlClient}>
          {/* @ts-ignore */}
          <DynamicMDXProvider components={MDX_COMPONENT_MAP}>
            <DynamicSbProvider>
              <SbStateSync />
              <WalletStateSync />
              <Component {...props} />
              <DynamicToaster />
            </DynamicSbProvider>
          </DynamicMDXProvider>
        </DynamicUrqlProvider>
      </DynamicStoreProvider>
    </DynamicPrivyProvider>
  )
}

export default MyApp
