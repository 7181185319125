// SEO CONFIG
export const META_TITLE_SUFFIX = ' | NSFW.app'

export const DEFAULT_META_TITLE = 'NSFW+ | Community for sex positive creators'

export const DEFAULT_META_DESC =
  'Not Safe For Work enables authentic, verified, and sex positive humans to connect and monetize content without stigma or censorship.'

// PAGE CACHE CONTROLS
export const PROFILE_MAX_AGE_SEC = process.env.PROFILE_MAX_AGE_SECONDS ?? '10'
export const PROFILE_STALE_WHILE_REVALIDATE_SEC =
  process.env.PROFILE_STALE_WHILE_REVALIDATE_SEC ?? '59'

export const POST_MAX_AGE_SEC = process.env.POST_MAX_AGE_SECONDS ?? '10'
export const POST_STALE_WHILE_REVALIDATE_SEC = process.env.POST_STALE_WHILE_REVALIDATE_SEC ?? '59'
