import type { ChainListItem } from 'lib/web3/chains'
import { SupportedEnv, SupportedEnvironments } from './environments'

import {
  Chain,
  base,
  baseSepolia,
  bsc,
  bscTestnet,
  optimism,
  optimismSepolia,
  polygon,
  polygonAmoy
} from 'viem/chains'
import { Network, ProductionNetworks, TestingNetworks } from '@nsfw-app/crypto'

// NOTE: Update chainsMap.ts if new chains are added
const STAGE_CHAINS: Record<TestingNetworks, Chain> = {
  [baseSepolia.id]: baseSepolia,
  [optimismSepolia.id]: optimismSepolia,
  [polygonAmoy.id]: polygonAmoy,
  [bscTestnet.id]: bscTestnet
}

const BETA_CHAINS: Record<ProductionNetworks, Chain> = {
  [base.id]: base,
  [optimism.id]: optimism,
  [polygon.id]: polygon,
  [bsc.id]: bsc
  // [Network.ARBITRUM]: CHAIN_LIST[Network.ARBITRUM]
}

const getChainsForEnv = (env: SupportedEnvironments): Record<string, Chain> => {
  switch (env) {
    case SupportedEnv.STAGE:
      return STAGE_CHAINS
    case SupportedEnv.BETA:
      return BETA_CHAINS
    default:
      throw new Error('Unsupported Environment')
  }
}

export const CHAINS = getChainsForEnv(process.env.NEXT_PUBLIC_NSFW_ENV as SupportedEnvironments)

export const mapChainToNetwork = (id: Network, chain: ChainListItem) => ({
  id: id,
  name: chain.name,
  symbol: chain.nativeCurrency.symbol
})

export const findChain = (id: Network): Chain => CHAINS[id]
