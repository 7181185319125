import Cookies from 'js-cookie'
import { createClient } from 'urql'
import { TOKEN_COOKIE, API_URL } from 'config'
import { getAuthHeaders } from 'lib/helpers'

/* Note this urqlClient is only used on client-side Next.js pages.
 * Pages using SSR/SSG should use the functions in lib/urql/nextClient.ts (or wrappers in /hocs).
 */
export const urqlClient = createClient({
  url: API_URL,
  requestPolicy: 'network-only',
  fetchOptions: () => {
    const token = Cookies.get(TOKEN_COOKIE)
    return {
      headers: getAuthHeaders(token),
    }
  },
})
