// TODO: move to nsfw/shared?
export enum ErrorCodes {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  FORBIDDEN = 'FORBIDDEN',
  BAD_INPUT = 'BAD_USER_INPUT',
  GENERIC_NOT_FOUND = 'NotFoundError',
  INVALID_PASSWORD = 'err.auth.service.invalid-password',
  LOGIN_WEAK_PASSWORD = 'err.login.weak-password',
  CREATE_ACCOUNT_WEAK_PASSWORD = 'err.create-account.weak-password',
  CREATE_ACCOUNT_EMAIL_IN_USE = 'err.account.email.already-registered',
  INVALID_USER = 'err.auth.service.invalid-user',
  INVALID_USER_RECOVERY = 'err.account-recoverry.invalid-user',
  PASSWORD_TOKEN_REUSED = 'err.account.recover.set-password.token-reused',
  SET_PASSWORD_REUSED_TOKEN = 'err.set-password.token-reused',
  CREATOR_PENDING_APPROVAL = 'err.account.pending-approval',

  RESET_PASSWORD = 'err.auth.service.resetPassword',
  RESET_TOKEN_EXPIRED = 'err.account.recovery.token-expired',
  RESET_PASSWORD_WEAK_PASSWORD = 'err.set-password.weak-password',

  POST_NOT_FOUND = 'err.posts.not-found',

  // Onboarding
  APPROVAL_NOT_FOUND = 'err.approvals.not-found',
  APPROVAL_USER_NOT_FOUND = 'err.user.not-found',

  // GrapqhQL request errors
  BAD_USER_INPUT = 'BAD_USER_INPUT',

  //Web3 Wallet Errors
  WALLET_NOT_CONNECTED = 'err.wallet.not-connected',
  WALLET_INVALID_NETWORK = 'err.wallet.invalid-network',
  WALLET_NOT_LINKED = 'err.wallet.not-linked',
  WALLET_IN_USE = 'err.link-wallet.wallet-in-use',

  // Plans
  NULL_PLANS = 'err.view-creator-plans.null',

  DELETE_POST = 'DELETE_POST',

  // pages/change-email
  CHANGE_EMAIL_TOKEN_REUSED = 'err.update-email.token-reused',
  CHANGE_EMAIL_REGISTERED = 'err.update-email.already-registered',
  CHANGE_EMAIL_REUSED_TOKEN = 'err.update-email.token-reused',
  CHANGE_EMAIL_EXPIRED_TOKEN = 'err.update-email.expired',
  CHANGE_EMAIL_EXPIRED_TOKEN_CLAIM = 'err.update-email.token-expired',

  // profile settings set email
  SET_EMAIL_ALREADY_SET = 'err.account.set-email.existing-email',
  SET_EMAIL_NO_ADDRESS = 'err.account.set-email.no-address',
  SET_EMAIL_ALREADY_REGISTERED = 'err.account.set-email.already-registered',

  //Withdrawal
  EMPTY_WALLET = 'EMPTY_WALLET',
  EMPTY_WITHDRAWAL_WALLET = 'EMPTY_WITHDRAWAL_WALLET',
  WITHDRAWAL_MIN_AMOUNT = 'err.withdrawal.min-amount',
  PENDING_WITHDRAWAL = 'err.withdrawal.in-progress',

  // Posts
  MAX_COMMENT_LEN = 'err.comment.max-size',

  // Onboarding/KYC
  KYC_RENEWAL = 'err.kyc.renewal',

  // TODO backend should return general non-utf error, not scoped to feature.
  UTF8_ONLY_COMMENT = 'err.comment.utf-8-only',
  UTF8_ONLY_BIO = 'err.update-bio.non-utf-8-chars',

  //DEFAULT
  DEFAULT = 'DEFAULT',
}
