import { PostStatus, PostType } from 'graphql/types'
import { AppRoutes } from './routes'

// URLs
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST as string
export const API_URL = process.env.NEXT_PUBLIC_API_URL as string
export const SITE_DOMAIN = process.env.NEXT_PUBLIC_SITE_DOMAIN
export const IMAGE_DOMAIN = process.env.NEXT_PUBLIC_IMAGE_DOMAINS?.split(',')[0]

// Home page
export const DEFAULT_HOME_ROUTE = AppRoutes.DISCOVER

// App config
export const DEFAULT_LOCALE = 'en-AU'
export const DEFAULT_IMAGE_QUALITY = 75
export const PLATFORM_FEE = 0.1

// Storage keys
export const TOKEN_COOKIE = 'auth-token'
export const REFERRAL_CODE = 'referral-code'
export const INTRO_VIEWED = 'intro-viewed'
export const CONNECTED_WALLET = 'connected-wallet'

// Form defaults
export const DEFAULT_POST_TYPE = PostType.TEASER
export const DEFAULT_POST_STATUS = PostStatus.DRAFT

// GraphCMS
export const GRAPHCMS_CONTENT_API_URL = process.env.NEXT_GRAPHCMS_CONTENT_API_URL ?? ''

// Feed
export const FEED_PAGE_LIMIT = 10

export * from './layout'
export * from './chains'
export * from './currencies'
export * from './environments'
export * from './errorCodes'
export * from './errors'
export * from './footerLinks'
export * from './routes'
export * from './seo'
export * from './validations'
export * from './wallets'
