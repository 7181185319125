// -- Global
export const TOPNAV_HEIGHT_MOBILE = 56
export const TOPNAV_HEIGHT_DESKTOP = 68
export const FOOTER_HEIGHT = 64
export const CATEGORIES_MENU_HEIGHT = 52

// -- Feed
export const LAYOUT_CENTER_COL_WIDTH = 544
export const THREADS_LAYOUT_CENTER_COL_WIDTH = 620
export const LAYOUT_LEFT_COL_WIDTH = 207
export const LAYOUT_RIGHT_COL_WIDTH = 320

// -- Profiles
export const PROFILE_BANNER_EXPANDED_HEIGHT_PERCENT = 100
export const PROFILE_BANNER_CARD_HANDLE_HEIGHT = 24

export const PROFILE_BANNER_COLLAPSED_HEIGHT = 82
export const PROFILE_BANNER_EXPANDED_HEIGHT = 392
export const PROFILE_BANNER_CARD_HANDLE_COLLAPSED_HEIGHT = 16

export const PROFILE_DESKTOP_RIGHT_COL_HEIGHT = `calc(var(--app-height) - (${
  TOPNAV_HEIGHT_DESKTOP + FOOTER_HEIGHT
}px + 32px))`
